import React from 'react';

import Layout from '../components/Layout';

import pic1 from '../assets/images/b1.png';
import pic2 from '../assets/images/test2.jpg';
import pic3 from '../assets/images/oven1.jpg';
import pic4 from '../assets/images/history3.jpg';
import pic5 from '../assets/images/about3.jpg';
import pic6 from '../assets/images/b2.png';
import pic7 from '../assets/images/b6.jpg';
import pic0 from '../assets/images/logot.svg';

import config from '../../config';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <center>
      <div className="image">
        <div className="">
        <img src={pic0} alt="" /> 
        </div>
        
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
      </div>
      </center>
    </section>

    <section id="wrapper">
      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#sausage" className="image">
            <img src={pic1} alt="" />
          </a>
          <div className="content">
            <h2 className="major">ROMANIAN SAUSAGES</h2>
            <h2>
            Mititei
            </h2>
            <a href="/#sausage" className="special">
              Learn more
            </a>
          </div>
        </div>
      </section>
      
      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/Testimonials" className="image">
            <img src={pic2} alt="" />
          </a>
          <div className="content">
            <h2 className="major">TESTIMONIALS</h2>
            <p>
              Hear what our customers have to say about our <i>delicios</i> Romanian meats
            </p>
            <a href="/Testimonials" className="special">
              Learn more
            </a>
          </div>
        </div>
      </section>
      
      <section id="three" className="wrapper spotlight style3">
        <div className="inner">
          <a href="/Tips" className="image">
            <img src={pic3} alt="" />
          </a>
          <div className="content">
            <h2 className="major">TIPS & TRICKS</h2>
            <p>
              Excellent ways to help add an authentic Romanian touch to your cooking 
            </p>
            <a href="/Tips" className="special">
              Learn more
            </a>
          </div>
        </div>
      </section>


      
      <section id="four" className="wrapper alt style1">
        <div className="inner">
          <h2 className="major">A TASTE OF ROMANIA</h2>
          <p>
            Find out more about us
          </p>
          <section className="features">
            <article>
              <a href="/About/#history" className="image">
                <img src={pic4} alt="" />
              </a>
              <h3 className="major">HISTORY</h3>
              <p>
              Learn about the deep rooted tradition and cultural heritage that goes into our recipes. 
              </p>
              <a href="/About/#history" className="special">
                Learn more
              </a>
            </article>
            <article>
              <a href="/About" className="image">
                <img src={pic5} alt="" />
              </a>
              <h3 className="major">ABOUT US</h3>
              <p>
              What we do, why we do it, and what makes our meat stand out from the rest.
              </p>
              <a href="/About" className="special">
                Learn more
              </a>
            </article>
          
          </section>


          
        </div>
      </section>
      <br></br>
      <section className="wrapper sausage">
      <div className="inner">
      <h2 className="major">AUTHENTIC RECIPES</h2>
      <p>Only our finest ingredients make the cut</p>
      <section className="features">
      
          <article>
              <div className="image">
                <img src={pic6} alt="" />
              </div>
              <h3 className="major">HIGH QUALITY INGREDIENTS</h3>
              <p>
              We use ingredients of the highest quality to ensure a our customers have a mouthwatering experience.
              </p>
              <h3 className="major">TRADITIONALLY MADE</h3>
              <p>
              We stay true to our Romanian roots, which means that our meats are made using the same tried and true methods that Romanian butchers have always employed.
              </p>
              
            </article>
            <article id="sausage">
              <div className="image">
                <img src={pic7} alt="" />
              </div>
              <h3 className="major">THE MITITEI</h3>
              <p>A popular story claims that the Mititei were invented in the late 19th century by Iordache Ionescu, 
                a cook working in one of the many busy pubs in the Lipscani district of Bucharest.
                According to the legend, Ionescu was famous for his fresh sausages, 
                but during a particularly busy day he ran out of casing and the idea of placing only the filling of the sausage on the grill came to him. 
                The improvised new dish proved an instant hit and their popularity continued to grow ever since. 
                The famous nearby restaurant Caru' cu Bere is also sometimes given as the birthplace of the Mititei.</p>
            </article>
           
            </section>
            </div>
            </section>
            
    </section>
  </Layout>
);

export default IndexPage;
